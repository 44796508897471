export default {
  menubar: {
    "About": "О приложении",
    "To partners and authors": "Партнерам и авторам",
    "Feedback":'Обратная связь'
  },
  homepage: {
    "TURN YOUR JOURNEY INTO AN EXCITING GAME!": "Преврати<br> путешествие в<br> <strong>увлекательную игру!</strong>",
    "Get on Google Play": "Скачать в Google Play",
    "Get on App Store": "Скачать в App Store",
    "UNIQUE ROUTES AND QUESTS ENHANCED WITH AUGMENTED REALITY FEATURES": "УНИКАЛЬНЫЕ МАРШРУТЫ И КВЕСТЫ С ЭЛЕМЕНТАМИ<br><strong>ДОПОЛНЕННОЙ РЕАЛЬНОСТИ</strong>",
    "SELECT A ROUTE YOU FIND APPEALING": "ВЫБЕРИ МАРШРУТ ПО ИНТЕРЕСАМ",
    "A flexible filter system will help you assemble the journey of your dreams": "Сортировка по фильтрам поможет составить путешествие твоей мечты.",
    "VISIT SCENIC LOCATIONS": "ПОСЕЩАЙ ЖИВОПИСНЫЕ МЕСТА",
    "The most interesting locations suggested by locals and enthusiasts of the respective regions": "Самые интересные локации от местных жителей и влюбленных в регион",
    "UNDERTAKE EXCITING QUESTS": "ПРОХОДИ ИНТЕРЕСНЫЕ КВЕСТЫ",
    "Experience the thrill of being an adventure movie character in pursuit of hidden treasure": "Возможность почувствовать себя героем приключенческого фильма в погоне за тайными сокровищами",
    "SEARCH FOR VALUABLE ARTIFACTS": "УЧАСТВУЙ В ПОИСКЕ ЦЕННЫХ АРТЕФАКТОВ",
    "A fundamentally new travel experience, enhancing the amazement of discovery through augmented reality technologies": "Принципиально новый опыт от путешествий, где эмоции от познания нового усиливаются инструментами дополненной реальности",
    "ENJOY GIFTS FROM OUR PARTNERS": "ПОЛЬЗУЙСЯ ВОЗНАГРАЖДЕНИЯМИ ОТ ПАРТНЕРОВ",
    "Discounts at cafés, restaurants and hotels": "Скидки в кафе, ресторанах, гостиницах",
    "CREATE AND SHARE YOUR OWN ROUTES": "СОЗДАВАЙ <strong>СВОИ МАРШРУТЫ</strong> И ДЕЛИСЬ ИМИ",
    "Ever wanted to make money on traveling? Now, the opportunity is yours!": "Давно хотел зарабатывать на путешествиях? <br> Теперь у тебя есть такая возможность!",
    "DEVELOP AN INTERESTING ROUTE OR QUEST": "DEVELOP AN INTERESTING ROUTE OR QUEST",
    "Share your favorite locations and inspire others to travel!": "Делись своими любимыми местами и вдохновляй других на путешествия!",
    "RECEIVE PARTNER REMUNERATION": "ПОЛУЧАЙ ПОДАРКИ ОТ НАШИХ ПАРТНЕРОВ",
    "30% of the cost of advertisements placed along your route will be credited to your account": "30% от стоимости рекламы, размещенной на твоем маршруте, отправляется на твой счет",
    "EARN WITH PROGRAMS": "ЗАРАБАТЫВАЙ НА ПРОГРАММАХ",
    "Authors of the most popular routes receive payouts": "Авторы самых популярных маршрутов получают выплаты. Так же ты можешь сделать свой маршрут платным и получать деньги от каждого прохождения",
    "TRACK POPULARITY AND RECEIVE LIKES": "СЛЕДИ ЗА ПОПУЛЯРНОСТЬЮ И ПОЛУЧАЙ ЛАЙКИ",
    "Collect feedback": "Собирай обратную связь, улучшай маршрут и получай больше прохождений. Чем больше людей пройдет твой маршрут, тем больше ты заработаешь!",
    "ARTIFACT REWARDS": "ARTIFACT REWARDS",
    "An artifact is a 'treasure chest' you find at the end of your journey, a culmination of the completed track. Depending on the route and complexity of the program, an artifact can yield a variety of rewards:": "An artifact is a 'treasure chest' you find at the end of your journey, a culmination of the completed track. Depending on the route and complexity of the program, an artifact can yield a variety of rewards:",
    "A gift from a Project Partner": "Подарок от партнера проекта",
    "Access to premium features of the application: exclusive quests and routes, valuable prizes": "Доступ к премиальным возможностям приложения: эксклюзивным квестам и маршрутам, ценным призам",
    "Prize cost in in-game currency. You can use the currency to exchange artifacts and discover new fascinating routes": "Стоимость во внутриигровой валюте. Именно с помощью нее ты можешь обмениваться артефактами, открывать новые интересные маршруты",
  
    "PARTICIPATE IN THE SEARCH FOR VALUABLE ARTIFACTS": "УЧАСТВУЙ В ПОИСКЕ ЦЕННЫХ АРТЕФАКТОВ",
    "CREATE AN INTERESTING ROUTE OR QUEST": "СОЗДАЙ ИНТЕРЕСНЫЙ МАРШРУТ ИЛИ КВЕСТ",
    "WHAT YOU GET FOR THE ARTIFACTS": "ЧТО ВЫ ПОЛУЧАЕТЕ ЗА <strong>АРТЕФАКТЫ</strong>",
    "When participants collect artifacts during the quests": "<strong>Артефакт</strong> – это найденный тобой в конце путешествия «сундук с сокровищами», кульминация прохождения трека.В зависимости от маршрута и сложности программы артефакт может иметь разные выражения: ",
    "Gain access to the premium features of the app: exclusive quests, special routes, and valuable prizes": "Доступ к премиальным возможностям приложения: эксклюзивным квестам и маршрутам, ценным призам",
    "Cost in in game currency":"Стоимость во внутриигровой валюте. Именно с помощью нее ты можешь обмениваться артефактами, открывать новые интересные маршруты"
  },
  about: {
    "Choose a route based on your interests": "Выбери маршрут по интересам",
    "Sorting by filters will help you create the journey of your dreams": "Сортировка по фильтрам поможет составить путешествие твоей мечты",
    "Gain access to related travel services": "Получи доступ к сопутствующим трэвел-услугам",
    "Participate in an exciting game with augmented reality elements": "Участвуй в захватывающей игре с элементами дополненной реальности",
    "Compete with other travelers in treasure hunting": "Соревнуйся с другими путешественниками в поиске кладов",
    "Explore unknown and rediscover familiar places": "Исследуй неизвестные и по-новому открывай знакомые места",
    "Create your own routes": "Создавай собственные маршруты",
    "Feel like a treasure hunter": "Почувствуй себя искателем сокровищ",
    "Artifactum is": "<strong>Artifactum</strong> – это путеводитель по необычным местам, где каждый маршрут представляет собой увлекательный квест с элементами дополненной реальности.<br> Это новый формат путешествий, объединяющий познавательный и игровой формат",
    "You select a program": "Вы выбираете программу по своим интересам, посещаете знаковые музеи или идете по живописным горным тропам, а параллельно участвуете в поиске ценных артефактов",
    "The application": "Приложение также дает доступ к трэвел-услугам на маршруте: поиску гида, бронированию гостиницы и столиков в ресторанах",
    "How does it work?": "Как это работает?",
    "Along the route, there are checkpoints that are recorded using augmented reality technology": "На маршруте вас ждут контрольные точки, достижение которых фиксируется при помощи технологии дополненной реальности",
    "The final point of": "Финальная точка маршрута содержит сундук с сокровищем - артефактом. Это сокровище можно получить только после прохождения всех контрольных точек маршрута",
    "The more valuable the artifact": "Чем ценнее артефакт, тем больше возможностей у вас есть: вы получаете доступ к новым маршрутам, возможность продать артефакт за криптовалюту на бирже",
    "What other features does the application provide?": "Какие еще возможности дает приложение?",
    "It offers the selection of tourist routes based on your current location": "Выбор туристических маршрутов на основании текущего местоположения",
    "Answer to the question": "Ответ на вопрос: «Что интересного можно здесь посмотреть?», когда хочется получить концентрированные впечатления при ограниченном времени",
    "Help in planning your trip": "Помощь в планировании путешествия. Познакомьтесь с треками в разных регионах, а красочные фото ключевых локаций послужат дополнительным источником вдохновения",
    "Booking and payment for hotels": "Бронирование и оплата гостиниц, кафе, музеев. Трэвел-услуги в одном приложении",
    "The ability to share photos on social networks and with friends in the app": "Возможность делиться фотографиями в социальных сетях и с друзьями в приложении",
    "Team treasure hunt competitions": "Командные соревнования по поиску кладов. Создавайте пользовательские группы и запускайте в них соревнования по маршруту",
    "Every month, access new tracks": "Каждый месяц - доступ к новым трекам",
    "Personal guide services along the route": "Услуги персонального гида по маршруту",
    "Save on food, accommodation, and entertainment thanks to special conditions and promotions from our partners": "Экономия на питании, проживании и досуге благодаря специальным условиям и акциям от наших партнеров",
    
    "travel with enjoyment": "ПУТЕШЕСТВУЙТЕ C <strong>УДОВОЛЬСТВИЕМ!</strong>"
  },
  partners: {
    "FOR PARTNERS AND AUTHORS": "ПАРТНЕРАМ И АВТОРАМ",
    "Who benefits from collaborating with us": "Кому выгодно сотрудничать с нами?",
    "For tour companies": "Туристическим компаниям",
    "Are you an": "Вы организатор городских прогулок или экстремальных туров? Вне зависимости от формата ваших программ вы получаете доступ к своей целевой аудитории и предлагаете им путешествия принципиально нового формата. Публикуйте свои предложения на нашей платформе и создавайте уникальные туры, отличающиеся от программ ваших конкурентов. В путешествии-квесте туристы чувствуют себя героями фильмов про Индиану Джонса или Лару Крофт, а вы получаете эмоциональную связку – вас запомнят, как умеющего оставлять яркие впечатления организатора путешествий. Мы предлагаем вам платформу для презентации, бронирования и оплаты ваших туров и программ. Хотите стать партнером, получать новых клиентов и повышать лояльность имеющихся? Мы вышлем вам подробные инструкции, обучающие материалы по созданию маршрутов и работе в системе на почту",
    "For guides and tour operators": "Гидам и экскурсоводам",
    "Publish routes": "Публикуйте маршруты из своей коллекции с аудиосопровождением и фотографиями. Без вашего присутствия сотни туристов будут проходить ваши программы. Если трек становится популярным, то вы получаете деньги за каждый уникальный проход вашего трека туристом, а также призы от наших партнеров. Помимо этого, вы можете установить цену за прохождение маршрута и получать деньги каждый раз, когда турист пройдет по вашему маршруту Хочешь в команду? Оставляй свои контакты в форме ниже",
    "For cafes, bars, shops, restaurants, theaters, museums": "Кафе, барам, магазинам, ресторанам, театрам, музеям",
    "Place artifacts near": "Размещайте артефакты вблизи или внутри ваших заведений и привлекайте новых клиентов! Артефакт может быть размещен на одном из маршрутов, проходящем рядом с вашим заведением. Если таких маршрутов пока нет, то у вас есть возможность его бесплатно создать. Чем популярнее и интереснее будет квест, тем больше посетителей вы потенциально сможете привлечь. Вы также можете размещать информацию о скидках, специальных предложениях и новинках меню в приложении. Пользователи увидят информацию в виде всплывающего элемента дополненной реальности – у такого креативного рекламного хода гораздо больше шансов на успех!",
    "Personal account": "Личный кабинет",
  },
  feedback: {
    "Enter name": "Введите имя",
    "Name": "Имя",
    "Enter e-mail": "Введите e-mail",
    "Enter your message": "Введите ваше сообщение",
    "Send": "Отправить",
    "Input password": "Введите пароль",

  },
  footer: {
    "Privacy Policy": "Политика конфиденциальности"
  },
  "contacts": "Контакты",
  "routes": "маршрутов",
  "quests": "квестов",
  "authors": "авторов",
  "gifts": "подарков",

  "Log In":"Вход",
  "Advertising partner": "Партнер по рекламе",
  "Author": "Автор",
  "To come in": "Войти",
  "Forgot your password": "Забыли пароль",
  
  
  
  cont: {
    "under development": "Данная страница находится в разработке..."
  }
}
