import MapPointImageListItem from './MapPointImageListItem';
import SchedulePointModel from './SchedulePointModel';


export default class MapPointListItem  {
	Id: number;
	Title?: string;
	Description?: string;
	Number: number;
	Latitude: number;
	Longitude: number;
	SchedulePoint: SchedulePointModel;
	Images: Array<MapPointImageListItem>;
	PreviousPointDistance?: number;
	PreviousPointDuration?: number;
	constructor(obj?: Partial<MapPointListItem>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
