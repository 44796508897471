import PageRequest from '@/api/dataSource/api/PaginationHelper/Lib/PageRequest';
import { MapFilterModel } from '@/components/controls/ui-map';
import { CropImageModel } from '@/components/controls/ui-map/models-ext/CropImageModel';
import MapRouteClusterItemResponse from '@/components/controls/ui-map/models-ext/MapRouteClusterItemResponse';
import { PaginationModel } from '@/components/controls/ui-map/models-ext/PaginationModel';
import { RouteFilterModel } from '@/components/controls/ui-map/models-ext/RouteFilterModel';
import RouteItemClusterModel from '@/components/controls/ui-map/models-ext/RouteItemClusterModel';

import moment from 'moment';
import { ref } from 'vue';
import { GetMapRouteClustersRequest } from './models';
import MapCoordinates from './models/MapCoordinates';
import MapPointCoordinates from '@/components/controls/ui-map/models-ext/MapPointCoordinates';

export default class MainAdapterService {
  useApiStore: any=Object()
  /**
   * * Фильтр
   */
  filter = ref(new RouteFilterModel());
  /**
   * * Идет ли загрузка
   */
  /**
 * * Модель пагинации
 */
  pagination = ref(new PaginationModel());
  /**
 * * Текущая страница
 */
  current = () => this.pagination.value.Current;
  /**
 * * Кластеры
 */
  clusters = ref(new Array<RouteItemClusterModel>());
  /**
   * * Количество пропускаемых значений
   */
  skip = 
    () => (this.pagination.value.Current - 1) * this.pagination.value.Size;
  /**
 * * Количество значений за одну загрузку
 */
  take = () => this.pagination.value.Size;
  isLoading = ref(false);
  /**
 * * Открыть
 */
  stopLoading = () => {
    this.isLoading.value = false;
  };
  constructor() { }

  mapCluster = (cluster: MapRouteClusterItemResponse) => {
    return new RouteItemClusterModel({
      Count: cluster.Count,
      Id: cluster.MapRoute?.Id,
      Title: cluster.MapRoute?.Title,
      Latitude: cluster.Position.Latitude,
      Longitude: cluster.Position.Longitude,
      DetailUrl: cluster.MapRoute?.PreviewUrl,
      Description: cluster.MapRoute?.Description,
      PreviewImages: cluster.MapRoute
        ? [
          new CropImageModel({
            Id: 1,
            CropImage: cluster.MapRoute.PreviewUrl,
          }),
        ]
        : [],
      ImageId: 0,
      Number: 0,
    });
  };
  /**
 * * Получить маршруты
 * @param filter
 */
  async getRouteClusters(_filter: MapFilterModel): Promise<Array<RouteItemClusterModel>> {



    let x=  [
        {
          "Id": 197022,
          "Count": 3,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.620605269196812,
            "Latitude": 46.836733814937965
          }
        },
        {
          "Id": 197024,
          "Count": 1,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.612481590593237,
            "Latitude": 46.84379461087283
          },
          "MapRoute": {
            "Id": 8491,
            "Title": "Translation testing",
            "Description": "Route translation",
            "Distance": 11703200.099514637,
            "Type": 0,
            "Transmission": 0,
            "Price": 0.0000,
            "CommentsCount": 0,
            "PointsCount": 5,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8491/preview-fb3416fa-6c38-4c36-a307-181dc3ac2d0d.jpg",
            "Owner": {
              "UserId": 38,
              "NickName": "astro-m",
              "PhotoUrl": "/files/Users/38/daab2a00-5f1b-4048-aef1-794a68821718.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10063,
              "Latitude": 46.84379461087283,
              "Longitude": 29.612481590593237
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197027,
          "Count": 4,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.661970138549787,
            "Latitude": 46.83265863717263
          }
        },
        {
          "Id": 197030,
          "Count": 1,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.313526146579537,
            "Latitude": 46.812122481124625
          },
          "MapRoute": {
            "Id": 8545,
            "Title": "Супер-1",
            "Description": "фывыфвфвыфыв",
            "Distance": 133966.77445270572,
            "Type": 0,
            "Transmission": 0,
            "Price": 0.0000,
            "CommentsCount": 0,
            "PointsCount": 11,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8545/preview-a2851cbe-56cb-4049-8634-1f4952825cfa.jpg",
            "Owner": {
              "UserId": 91,
              "PhotoUrl": "/files/Users/91/f613dd53-bf15-4c5f-a9b1-5b87fbdd01e3.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10163,
              "Latitude": 46.812122481124625,
              "Longitude": 29.313526146579537
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197035,
          "Count": 1,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.51574322767556,
            "Latitude": 46.86825042946052
          },
          "MapRoute": {
            "Id": 8536,
            "Title": "новый тест gfdgfdgfd  Копия",
            "Description": "vsdvdsvsd",
            "Distance": 15429.636060234898,
            "Type": 0,
            "Transmission": 0,
            "Price": 0.0000,
            "CommentsCount": 0,
            "PointsCount": 24,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8536/preview-16f8c811-0bf6-4d33-bf55-964c711c5b0c.png",
            "Owner": {
              "UserId": 38,
              "NickName": "astro-m",
              "PhotoUrl": "/files/Users/38/daab2a00-5f1b-4048-aef1-794a68821718.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10107,
              "Latitude": 46.86825042946052,
              "Longitude": 29.51574322767556
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197037,
          "Count": 1,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.42211736798072,
            "Latitude": 46.85953992633039
          },
          "MapRoute": {
            "Id": 8548,
            "Title": "Супер-1 Копия",
            "Description": "фывыфвфвыфыв",
            "Distance": 78770.0765378781,
            "Type": 0,
            "Transmission": 0,
            "Price": 0.0000,
            "CommentsCount": 0,
            "PointsCount": 6,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8548/preview-37303ac0-58f6-450d-b542-f96505bf5cd6.jpg",
            "Owner": {
              "UserId": 91,
              "PhotoUrl": "/files/Users/91/f613dd53-bf15-4c5f-a9b1-5b87fbdd01e3.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10185,
              "Latitude": 46.85953992633039,
              "Longitude": 29.42211736798072
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197039,
          "Count": 25,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.241085024550564,
            "Latitude": 46.81462873466413
          },
          "MapRoute": {
            "Id": 8549,
            "Title": "Супер-2",
            "Description": "фывыфвфвыфыв",
            "Distance": 125547.69308210311,
            "Type": 0,
            "Transmission": 0,
            "Price": 1.0000,
            "CommentsCount": 0,
            "PointsCount": 6,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8549/preview-2ffc7d72-ca4f-499b-a89a-ff96662ef8f7.jpg",
            "Owner": {
              "UserId": 91,
              "PhotoUrl": "/files/Users/91/f613dd53-bf15-4c5f-a9b1-5b87fbdd01e3.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10186,
              "Latitude": 46.81462873466413,
              "Longitude": 29.241085024550564
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197041,
          "Count": 3,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.645690917968754,
            "Latitude": 46.83717983412735
          }
        },
        {
          "Id": 197043,
          "Count": 10,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.628555630189677,
            "Latitude": 46.836521980235524
          },
          "MapRoute": {
            "Id": 8541,
            "Title": "авыавыавыавы",
            "Description": "oo",
            "Distance": 11086.596212592947,
            "Type": 0,
            "Transmission": 0,
            "Price": 10.0000,
            "CommentsCount": 0,
            "PointsCount": 6,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8541/preview-ecaf4fa7-1fe5-49f7-90fc-6fe67bc979aa.jpg",
            "Owner": {
              "UserId": 38,
              "NickName": "astro-m",
              "PhotoUrl": "/files/Users/38/daab2a00-5f1b-4048-aef1-794a68821718.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10123,
              "Latitude": 46.836521980235524,
              "Longitude": 29.628555630189677
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197053,
          "Count": 1,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.51756053208344,
            "Latitude": 46.81562178463312
          },
          "MapRoute": {
            "Id": 8551,
            "Title": "Супер-3",
            "Description": "фывыфвфвыфыв",
            "Distance": 28047.15896191854,
            "Type": 0,
            "Transmission": 0,
            "Price": 1.0000,
            "CommentsCount": 0,
            "PointsCount": 3,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8551/preview-b6c1e72c-f570-46ad-8477-bd96a9acfb4b.jpg",
            "Owner": {
              "UserId": 91,
              "PhotoUrl": "/files/Users/91/f613dd53-bf15-4c5f-a9b1-5b87fbdd01e3.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10201,
              "Latitude": 46.81562178463312,
              "Longitude": 29.51756053208344
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197060,
          "Count": 5,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.667513743350007,
            "Latitude": 46.837385087283224
          }
        },
        {
          "Id": 197061,
          "Count": 9999,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.552849475701098,
            "Latitude": 46.85393045893359
          },
          "MapRoute": {
            "Id": 8542,
            "Title": "новый маршрут ",
            "Description": "авыавыа",
            "Distance": 98363.97605998932,
            "Type": 0,
            "Transmission": 0,
            "CommentsCount": 0,
            "PointsCount": 4,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8542/preview-f9e528d5-5114-46d0-aee5-59b7bb7fde71.jpg",
            "Owner": {
              "UserId": 38,
              "NickName": "astro-m",
              "PhotoUrl": "/files/Users/38/daab2a00-5f1b-4048-aef1-794a68821718.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10140,
              "Latitude": 46.85393045893359,
              "Longitude": 29.552849475701098
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197064,
          "Count": 1,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.7,
            "Latitude": 46.786
          },
          "MapRoute": {
            "Id": 8543,
            "Title": "копия маршрут ",
            "Description": "авыавыа",
            "Distance": 5894817.54436212,
            "Type": 0,
            "Transmission": 0,
            "Price": 0.0000,
            "CommentsCount": 0,
            "PointsCount": 6,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8543/preview-58a6bf08-74c3-476b-9c1a-2228d9631907.jpg",
            "Owner": {
              "UserId": 38,
              "NickName": "astro-m",
              "PhotoUrl": "/files/Users/38/daab2a00-5f1b-4048-aef1-794a68821718.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10145,
              "Latitude": 46.786,
              "Longitude": 29.7
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197106,
          "Count": 6,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.606144424760718,
            "Latitude": 46.85015254583923
          }
        },
        {
          "Id": 197164,
          "Count": 1,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.61948394775391,
            "Latitude": 46.82473092332481
          },
          "MapRoute": {
            "Id": 8515,
            "Title": "asdf",
            "Description": "bfdthfgjnfgyjngfs",
            "Distance": 0.0,
            "Type": 0,
            "Transmission": 0,
            "CommentsCount": 0,
            "PointsCount": 1,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8515/preview-1efd2a05-8682-439d-8c81-cdc7322ab982.png",
            "Owner": {
              "UserId": 11,
              "NickName": "nickName6"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10075,
              "Latitude": 46.82473092332481,
              "Longitude": 29.61948394775391
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197173,
          "Count": 2,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.62777871728516,
            "Latitude": 46.847181491696944
          }
        },
        {
          "Id": 197176,
          "Count": 1,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.575138064101345,
            "Latitude": 46.85486959040957
          },
          "MapRoute": {
            "Id": 8499,
            "Title": "Поиск по маршруту",
            "Description": "Поиск по маршруту",
            "Distance": 0.0,
            "Type": 0,
            "Transmission": 0,
            "CommentsCount": 0,
            "PointsCount": 1,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8499/preview-8bf74628-188a-4b4c-b859-4e9714fc2122.png",
            "Owner": {
              "UserId": 38,
              "NickName": "astro-m",
              "PhotoUrl": "/files/Users/38/daab2a00-5f1b-4048-aef1-794a68821718.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10066,
              "Latitude": 46.85486959040957,
              "Longitude": 29.575138064101345
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197196,
          "Count": 1,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.412574740126736,
            "Latitude": 46.78031411656016
          },
          "MapRoute": {
            "Id": 7445,
            "Title": "Тест 0206",
            "Description": "Описание маршрута",
            "Distance": 0.0,
            "Type": 0,
            "Transmission": 0,
            "Price": 0.0000,
            "CommentsCount": 0,
            "PointsCount": 1,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/7445/preview-cc89d1fc-24d6-447d-8512-d04f0252b4ea.jpg",
            "Owner": {
              "UserId": 91,
              "PhotoUrl": "/files/Users/91/f613dd53-bf15-4c5f-a9b1-5b87fbdd01e3.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10202,
              "Latitude": 46.78031411656016,
              "Longitude": 29.412574740126736
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        },
        {
          "Id": 197198,
          "Count": 1,
          "Type": 0,
          "LanguageId": 0,
          "Position": {
            "Longitude": 29.58927154541016,
            "Latitude": 46.833421985001756
          },
          "MapRoute": {
            "Id": 8523,
            "Title": "вждывдчд",
            "Description": "Вдвдлвьчллчлч",
            "Distance": 0.0,
            "Type": 0,
            "Transmission": 0,
            "Price": 0.0000,
            "CommentsCount": 0,
            "PointsCount": 1,
            "PreviewUrl": "https://dev-s3.artifactum.io/files/MapRoutes/8523/preview-af30899e-1343-461e-b098-f8b97fa9d451.jpg",
            "Owner": {
              "UserId": 47,
              "NickName": "vdvvvv2358",
              "PhotoUrl": "/files/Users/47/a89c303c-cf00-4792-8401-4bffe1680c74.jpg"
            },
            "PassedStatus": 0,
            "FirstMapPoint": {
              "MapPointId": 10089,
              "Latitude": 46.833421985001756,
              "Longitude": 29.58927154541016
            },
            "IsBuy": false,
            "IsFavorite": false,
            "DistanceByCurrentPosition": 0.0
          }
        }
      ]


      return      x.map((x1) => this.mapCluster(x1 as any));
      
    
    const api = this.useApiStore().api;

    this.filter.value.Zoom = _filter.Zoom;
    this.filter.value.NorthEast = _filter.NorthEast;
    this.filter.value.SouthEast = _filter.SouthEast;

    
    // startLoading();

    const res = await api.MapRouteService.getRouteClustersAsync(
      new GetMapRouteClustersRequest({
        ZoomLevel: _filter.Zoom,
        LeftTopPoint: new MapCoordinates({
          Latitude: Number(_filter.NorthEast.lat),
          Longitude: Number(_filter.NorthEast.lng),
        }),
        RightBottomPoint: new MapPointCoordinates({
          Latitude: Number(_filter.SouthEast.lat),
          Longitude: Number(_filter.SouthEast.lng),
        }),
        Search: this.filter.value.Search,
        SearchAuthor: this.filter.value.SearchAuthor,
        Status:
          Number(this.filter.value.Status) != -1
            ? this.filter.value.Status.map(status => Number(status))
            : null,
        RouteType:
          Number(this.filter.value.Type) != -1 ? Number(this.filter.value.Type) : null,
        Type:
          Number(this.filter.value.Transmission) != -1
            ? Number(this.filter.value.Transmission)
            : null,
        Page: new PageRequest({
          Skip: this.skip(),
          Take: this.take(),
        }),
        DateStart:
          this.filter.value.Dates && this.filter.value.Dates.length == 2
            ? moment(this.filter.value.Dates[0]).startOf("day").toDate()
            : null,
        DateEnd:
          this.filter.value.Dates && this.filter.value.Dates.length == 2
            ? moment(this.filter.value.Dates[1]).startOf("day").toDate()
            : null,
      })
    )
      .then((clusterList: Array<MapRouteClusterItemResponse>) => {
       return clusterList.map((x) => this.mapCluster(x));
      })
      .finally(() => this.stopLoading());
    return res
  };

  /**
   * * Обновить кластеры
   */
  refreshClusters = async () => {
    const api = this.useApiStore().api;
    // startLoading();
    await api.MapRouteService.refreshClusterAsync().finally(async () => {
      await this.getRouteClusters(
        new MapFilterModel({
          Zoom: this.filter.value.Zoom,
          NorthEast: this.filter.value.NorthEast,
          SouthEast: this.filter.value.SouthEast,
        })
      );
    });
  };


}
