<!-- @format -->

<template>
	<layout>
		<div class="privacy-policy">
			<div class="container">
				<div id="nav-menu" class="nav-menu" :class="{ select: isSelect }">
					<div class="m1">
						<div class="title">
							<router-link to="home">
								<img src="@assets/img/logo/logo.svg" />&nbsp;<img
									src="@assets/img/logo/artifactum.svg"
							/></router-link>
						</div>
						<div class="menu-btn" id="menu-icon" @click="showMenu()">
							<img src="@assets/img/menu-svgrepo-com.svg" />
						</div>
					</div>
					<div class="m2">
						<div class="link" @click="isSelect = isTerms = false">
							<span v-if="lang != 'ru'">Privacy Policy </span>
							<span v-if="lang == 'ru'">{{ $t("footer.Privacy Policy") }}</span>
						</div>
						<div
							class="link"
							@click="
								() => {
									isTerms = true;
									isSelect = false;
								}
							"
						>
							<span v-if="lang !== 'ru'">Terms & Conditions</span>
							<span v-if="lang === 'ru'">Условия использования</span>
						</div>
					</div>
				</div>
				<template v-if="lang == 'ru'">
					<div v-if="!isTerms">
						<h1>{{ $t("footer.Privacy Policy") }}</h1>
						<p>
							<b style="font-size: 24px; font-weight: 900; color: #ffb83a"
								>"Artifactum"</b
							>
							(«Приложение») уважает конфиденциальность своих пользователей и обязуется
							защищать их личные данные. Настоящая Политика конфиденциальности описывает
							наши действия в отношении сбора, использования, обработки и раскрытия
							персональных данных.
						</p>

						<h2>Сбор и использование информации</h2>
						<p>Мы собираем следующие типы данных:</p>
						<ul>
							<li>Идентификатор пользователя</li>
							<li>Идентификатор устройства</li>
							<li>История покупок</li>
							<li>Взаимодействие с приложением</li>
							<li>Информация о сбоях приложения</li>
							<li>Данные о производительности</li>
							<li>Адрес электронной почты</li>
							<li>Местоположение</li>
						</ul>
						<p>
							Эти данные используются для улучшения функциональности приложения, обработки
							платежей и аналитики.
						</p>

						<h2>Услуги сторонних сервисов</h2>
						<p>
							Для аналитики и обработки покупок мы используем следующие сторонние сервисы:
						</p>
						<ul>
							<li>
								<a href="https://www.google.com/policies/privacy/">
									Сервисы Google Play
								</a>
							</li>
							<li>
								<a href="https://firebase.google.com/policies/analytics">
									Google Analytics для Firebase
								</a>
							</li>
							<li>
								<a href="https://firebase.google.com/support/privacy/">
									Firebase Crashlytics
								</a>
							</li>
						</ul>
						<p>
							Эти службы могут собирать информацию, используемую для идентификации вашего
							устройства.
						</p>

						<h2>Безопасность и хранение данных</h2>
						<p>Мы обязуемся защищать и обеспечивать безопасность ваших данных.</p>

						<h2>Права пользователя и запрос на удаление учетной записи</h2>
						<p>
							Вы имеете право запросить удаление данных, связанных с вашим аккаунтом в
							приложении. Для этого отправьте запрос по адресу
							<a href="mailto:support@artifactum.io">support@artifactum.io</a>, указав в
							нем email пользователя, с которым вы зарегистрированы в приложении. Мы
							незамедлительно обработаем ваш запрос и завершим удаление данных в течение
							60 дней с момента получения запроса.
						</p>
						<p>
							Пожалуйста, имейте в виду, что некоторые данные могут быть сохранены в
							соответствии с требованиями закона даже после удаления ваших данных. Мы
							примем необходимые меры для обеспечения безопасности и конфиденциальности
							всех оставшихся данных.
						</p>

						<h2>Политика конфиденциальности для детей</h2>
						<p>
							Мы не предоставляем услуги для лиц моложе 13 лет. Мы сознательно не собираем
							личную информацию детей младше 13 лет. В случае если мы обнаружим, что
							ребенок младше 13 лет предоставил нам личную информацию, мы немедленно
							удалим ее с наших серверов. Если вы являетесь родителем или опекуном и вам
							известно, что ваш ребенок предоставил нам личную информацию, пожалуйста,
							свяжитесь с нами, чтобы мы могли предпринять необходимые действия.
						</p>

						<h2>Изменения политики конфиденциальности</h2>
						<p>
							Мы оставляем за собой право вносить изменения в нашу Политику
							конфиденциальности. Мы будем уведомлять вас о любых изменениях, публикуя
							новую Политику конфиденциальности на этой странице.
						</p>

						<h2>Согласие</h2>
						<p>
							Устанавливая и используя Приложение, вы соглашаетесь с условиями настоящей
							Политики конфиденциальности.
						</p>

						<h2>Контактная информация</h2>
						<p>
							Если у вас есть вопросы или предложения относительно нашей Политики
							конфиденциальности, пожалуйста, свяжитесь с нами по адресу поддержки
							(support@artifactum.io).
						</p>
						<div class="contact-info">
							<p>
								Email: <a href="mailto:support@artifactum.io">support@artifactum.io</a>
							</p>
						</div>
					</div>
					<div v-else>
						<h1>Условия использования</h1>
						<p>
							Мы используем
							<a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">
								стандартные Условия использования Apple (EULA)</a
							>
						</p>
					</div>
				</template>
				<template v-if="lang != 'ru'">
					<div v-if="!isTerms">
						<h1>Privacy Policy</h1>
						<p>
							<b style="font-size: 24px; font-weight: 900; color: #ffb83a"
								>"Artifactum"</b
							>
							("the App") respects the privacy of its users and is committed to protecting
							their personal data. This Privacy Policy outlines our practices regarding
							the collection, use, processing, and disclosure of Personal Data.
						</p>

						<h2>Collection and Use of Information</h2>
						<p>We collect the following types of data:</p>
						<ul>
							<li>User ID</li>
							<li>Device ID</li>
							<li>Purchase History</li>
							<li>Product Interaction</li>
							<li>Crash Data</li>
							<li>Performance Data</li>
							<li>Email Address</li>
							<li>Location</li>
						</ul>
						<p>
							This data is used to improve the functionality of the App, process payments,
							and provide analytical insights.
						</p>

						<h2>Third-Party Services</h2>
						<p>
							We use the following third-party services for analytics and purchase
							processing:
						</p>
						<ul>
							<li>
								<a href="https://www.google.com/policies/privacy/"
									>Google Play Services
								</a>
							</li>
							<li>
								<a href="https://firebase.google.com/policies/analytics"
									>Google Analytics for Firebase
								</a>
							</li>
							<li>
								<a href="https://firebase.google.com/support/privacy/"
									>Firebase Crashlytics
								</a>
							</li>
						</ul>
						<p>These services may collect information used to identify your device.</p>

						<h2>Storage and Security of Data</h2>
						<p>We are committed to protecting and securing your data.</p>

						<h2>User Rights and Account Deletion Request</h2>
						<p>
							You have the right to request the deletion of analytical data associated
							with your user identifier. To do so, please send a request to
							<a href="mailto:support@artifactum.io">support@artifactum.io</a>, ensuring
							that your request includes your user ID (email). We will process your
							request promptly and complete the data deletion within 60 days from the date
							we receive your request with the included user ID (email).
						</p>
						<p>
							Please be aware that certain data may be retained as required by law or for
							legitimate business purposes even after the deletion of your analytical
							data. We will take necessary measures to ensure the security and
							confidentiality of any remaining retained data.
						</p>

						<h2>Children’s Privacy</h2>
						<p>
							These Services do not address anyone under the age of 13. We do not
							knowingly collect personally identifiable information from children under
							13. In the case we discover that a child under 13 has provided us with
							personal information, we immediately delete this from our servers. If you
							are a parent or guardian and you are aware that your child has provided us
							with personal information, please contact us so that we will be able to do
							necessary actions.
						</p>

						<h2>Changes to the Privacy Policy</h2>
						<p>
							We reserve the right to make changes to our Privacy Policy. We will notify
							you of any changes by posting the new Privacy Policy on this page.
						</p>

						<h2>Consent</h2>
						<p>
							By installing and using the App, you agree to the terms of this Privacy
							Policy.
						</p>

						<h2>Contact Information</h2>
						<p>
							If you have any questions or suggestions regarding our Privacy Policy,
							please contact us at:
						</p>
						<div class="contact-info">
							<p>
								Email: <a href="mailto:support@artifactum.io">support@artifactum.io</a>
							</p>
						</div>
					</div>
					<div v-else>
						<h1>Terms & Conditions</h1>
						<p>
							We are using the
							<a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
								>standard Apple Terms of Use (EULA)</a
							>
						</p>
					</div>
				</template>
			</div>
		</div>
	</layout>
</template>

<script lang="ts" setup>
import Vue, { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const lang = ref(navigator.language === "ru" ? "ru" : "en");
const isSelect = ref(false);
const isTerms = ref(false);

onMounted(() => {
	lang.value = route?.query?.lang?.toString() || localStorage.getItem("lang") || "ru";
});
const showMenu = () => {
	isSelect.value = !isSelect.value;
};
</script>

<style lang="less">
.privacy-policy {
	.container {
		line-height: 1.6;
		margin: 0;
		padding: 20px;
		/* max-width: 800px; */
		margin: auto;
		background: #fff;
		padding: 20px;
		border-radius: 8px;
		/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
	}
	h1 {
		/* text-align: center; */
		font-size: 34px;
		color: #333;
	}
	h2 {
		font-size: 24px;
		color: #555;
		margin-top: 24px;
	}
	p,
	ul {
		margin: 10px 0;
		color: #333;
	}
	ul {
		list-style-type: disc;
		margin-left: 20px;
	}
	.contact-info {
		margin-top: 20px;
	}
	.contact-info p {
		margin: 5px 0;
	}
	a {
		text-decoration: unset;
		color: #93b7d9;
	}
	.nav-menu {
		/* padding-top: 5px; */
		/* background: #e1d0d0; */
		margin-bottom: 30px;
		color: #ccc;
		overflow: hidden;
		min-height: 70px;
		max-height: 70px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		.m2 {
			margin-top: 10px;
		}
	}
	.nav-menu .m1 {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.nav-menu .m2 p {
		color: #ccc;
		margin-left: 20px;
		font-size: 18px;
		text-transform: uppercase;
		letter-spacing: 0.2em;
	}
	.link {
		text-decoration: unset;
		color: #93b7d9;
		padding-left: 20px;
		font-size: 22px;
		cursor: pointer;
	}
	.container a:hover {
		text-decoration: underline;
	}

	.nav-menu {
		transition: 0.3s;
		&.select {
			transition: 0.3s;
			min-height: 160px;
			.menu-btn {
				border: 3px solid #000;
			}
		}
		.title {
			font-size: 28px;
			font-weight: 900;
			padding: 10px;
			margin-left: 10px;
			color: #fff;
			a:hover {
				text-decoration: unset;
			}
		}
		.menu-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			border: #ccc 1px solid;
			border-radius: 8px;
			cursor: pointer;
			background: #ffb83a;
			color: #fff;
			transition: 0.3s;
			margin-right: 10px;
			margin-top: 5px;
			img {
				width: 50%;
			}
		}
	}
}
</style>
