export {default as Fullpage} from "./fullpage.vue"
export {default as BurgerMenu} from "./burger-menu.vue"
export {default as BurgerIcon} from "./burger-icon.vue"
export {default as Overlay} from "./overlay.vue"
export {default as Logo} from "./logo.vue"
export {default as RLink} from "./r-link.vue"
export { default as NavMenu } from "./nav-menu.vue"
export { default as NavButton } from "./nav-button.vue"
export { default as MainImage} from "./main-image.vue"
export { default as MainTitle} from "./main-title.vue"
export { default as DescrBlock} from "./descr-block.vue"
export { default as GoogleStoreButton} from "./googleStore-button.vue"
export { default as AppStoreButton } from "./appStore-button.vue"
export { default as SelectLanguage} from "./select-language.vue"
export { default as SelectLangBurger} from "./select-lang-burger.vue"
export { default as ButtonGroup} from "./button-group.vue"
export { default as VideoBlock} from "./video-block.vue"
export { default as VideoItem } from "./video-item.vue"
export * from "./ui"
