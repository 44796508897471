import { CropImageModel } from './CropImageModel';
import MapPointImageListItem from './MapPointImageListItem';
import MapPointListItem from './MapPointListItem';
import MapPointMarkerModel from './MapPointMarkerModel';
import SchedulePointModel from './SchedulePointModel';


export class MapPointModel {
  Id: number = 0;
  Title?: string;
  Description?: string;
  Number: number = 0;
  Latitude: number = 0;
  Longitude: number = 0;
  DetailUrl: string;
  PreviewImages?: Array<CropImageModel>;
  ImageId?: number;
  IsSchedule?: boolean = false;
  SchedulePoint?: SchedulePointModel;
  constructor(obj?: MapPointModel | MapPointImageListItem | MapPointMarkerModel) {
    if (obj) {
      if (obj instanceof MapPointModel) {
        Object.assign(this, obj);
      }
      else if (obj instanceof MapPointListItem) {
        this.Id = obj.Id;
        this.Title = obj.Title;
        this.Description = obj.Description;
        this.Number = obj.Number;
        if (obj.Latitude) this.Latitude = obj.Latitude;
        if (obj.Longitude) this.Longitude = obj.Longitude;
        if (obj.Images && obj.Images.length > 0) {
          this.DetailUrl = obj.Images[0].DetailUrl;
          this.ImageId = obj.Images[0].Id;
          this.PreviewImages = obj.Images.map(
            (x) =>
              new CropImageModel({
                Id: x.Id,
                Image: x.DetailUrl,
                CropImage: x.PreviewlUrl,
              })
          );
        }
        this.SchedulePoint = obj.SchedulePoint;
      } else if (obj instanceof MapPointMarkerModel) {
        this.Id = obj.Id;
        this.Title = obj.Title;
        this.Description = obj.Description;
        this.Number = obj.Number;
        if (obj.Latitude) this.Latitude = obj.Latitude;
        if (obj.Longitude) this.Longitude = obj.Longitude;
        this.DetailUrl = obj.DetailUrl;
        this.ImageId = obj.ImageId;
        this.PreviewImages = obj.PreviewImages;
        this.SchedulePoint = obj.SchedulePoint
      }
    }
  }

}
