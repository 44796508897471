<!-- @format -->

<template>
	<button class="google-button btn--animate" @click="clickSubmit">
		<svg width="16" height="34">
			<use xlink:href="@assets/img/svg-sprite.svg#google-button"></use>
		</svg>
		<span>{{ $t("homepage.Get on Google Play") }}</span>
	</button>
</template>

<script lang="ts" setup>
import Vue from "vue";

const clickSubmit = () => {
	window.open(
		"https://play.google.com/store/apps/details?id=com.tirscript.artifactum",
		"_blank"
	);
};
</script>

<style lang="css" scoped>
.google-button {
	max-width: 271px;
	width: 100%;
	height: 61px;
	margin-right: 25px;

	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 147%;
	color: #ffffff;
	background: #6d69c9;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.google-button svg {
	margin-right: 10px;
	fill: white;
}

/* ЭФФЕКТ ПРИ НАВЕДЕНИИ НА КНОПКИ форм
====================================================================== */
.btn--animate {
	display: flex;
	align-items: center;
	text-align: center;
	position: relative;
	transition: 0.5s;
	overflow: hidden;
}

.btn--animate::before,
.btn--animate::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background: #3f3b98;
	top: 0;
	left: -100%;
	opacity: 0.5;
	transition: 0.5s;
	z-index: 0;
}

.btn--animate::after {
	opacity: 1;
	transition-delay: 0.2s;
}

.btn--animate:hover span {
	color: white;
}

.btn--animate:hover svg {
	fill: white;
}

.btn--animate:hover::before,
.btn--animate:hover::after {
	left: 0;
}

.btn--animate span,
.btn--animate svg {
	position: relative;
	z-index: 2;
	color: #ffffff;
	transition: all 0.4s ease;
}

.btn--animate:active {
	transform: scale(0.95);
}

@media all and (max-width: 570px) {
	.google-button {
		margin-right: 0;
		max-width: 80%;
	}
}

@media all and (max-width: 430px) {
	.google-button {
		max-width: 100%;
	}
}

/* @media all and (min-width: 1920px) {
    .google-button {
        max-width: calc((100vw + 100000px)/(1920 + 100000) * (271 + 12000) - 12000px);
        height: calc((100vw + 100000px)/(1920 + 100000) * (60 + 2000) - 2000px);
        font-size: 0.9vw;
    }
    .google-button svg {
        margin-right: calc((100vw + 100000px)/(1920 + 100000) * (10 + 700) - 700px);
        width: calc((100vw + 100000px)/(1920 + 100000) * (16 + 1000) - 1000px);
        height: calc((100vw + 100000px)/(1920 + 100000) * (34 + 1000) - 1000px);
    }
} */
</style>
