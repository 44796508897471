export default {
  menubar: {
    "About": "PAR LIETOTNI",
    "To partners and authors": "PARTNERIEM UN AUTORIEM",
    "Feedback": 'ATSAUKSMES'
  },
  homepage: {
    "TURN YOUR JOURNEY INTO AN EXCITING GAME!": "PĀRVĒRT CEĻOJUMU<br> PAR <strong>AIZRAUJOŠU SPĒLI</strong>!",
    "Get on Google Play": "Lejupielādēt no Google Play",
    "Get on App Store": "Lejupielādēt noApp Store",
    "UNIQUE ROUTES AND QUESTS ENHANCED WITH AUGMENTED REALITY FEATURES": "UNIKĀLI MARŠRUTI UN KVESTI <br><strong>AR PAPILDINĀTĀS REALITĀTES ELEMENTIEM</strong></strong>",
    "SELECT A ROUTE YOU FIND APPEALING": "IZVĒLĒTIES MARŠRUTU ATBILSTOŠI SAVĀM INTERESĒM",
    "A flexible filter system will help you assemble the journey of your dreams": "Kārtošana pēc filtriem palīdzēs tev izveidot savu sapņu ceļojumu",
    "VISIT SCENIC LOCATIONS": "APMEKLĒT GLEZNAINAS VIETAS",
    "The most interesting locations suggested by locals and enthusiasts of the respective regions": "Interesantākās vietas no vietējiem iedzīvotājiem un tiem, kas mīl šo reģionu",
    "UNDERTAKE EXCITING QUESTS": "PIEDALIES INTERESANTOS KVESTOS",
    "Experience the thrill of being an adventure movie character in pursuit of hidden treasure": "Iespēja sajusties kā piedzīvojumu filmas varonim meklējot slepenus dārgumus.",
    "SEARCH FOR VALUABLE ARTIFACTS": "PIEDALIES VĒRTĪGU ARTEFAKTU MEKLĒŠANĀ",
    "A fundamentally new travel experience, enhancing the amazement of discovery through augmented reality technologies": "Principiāli jauna ceļojumu pieredze, kur emocijas, ko rada jaunu lietu atklāšana, pastiprina paplašinātās realitātes rīki",
    "ENJOY GIFTS FROM OUR PARTNERS": "IZMANTO PARTNERU BALVAS",
    "Discounts at cafés, restaurants and hotels": "Atlaides kafejnīcās, restorānos un viesnīcās",
    "CREATE AND SHARE YOUR OWN ROUTES": "IZVEIDO <strong>SAVUS MARŠRUTUS</strong> UN DALIETIES AR TIEM",
    "Ever wanted to make money on traveling? Now, the opportunity is yours!": "Jau sen gribēji pelnīt naudu ceļojot?<br>Tagad tev ir tāda iespēja! ",
    "DEVELOP AN INTERESTING ROUTE OR QUEST": "IZVEIDO INTERESANTU MARŠRUTU VAI KVESTU",
    "Share your favorite locations and inspire others to travel!": "Dalies ar savām iecienītākajām vietām un iedvesmo citus ceļot!",
    "RECEIVE PARTNER REMUNERATION": "SAŅEM DĀVANAS NO MŪSU PARTNERIEM",
    "30% of the cost of advertisements placed along your route will be credited to your account": "30% no tavā maršrutā izvietotajām reklāmas izmaksām tiks pārskaitīti uz tavu kontu",
    "EARN WITH PROGRAMS": "NOPELNI AR PROGRAMMĀM",
    "Authors of the most popular routes receive payouts": "Populārāko maršrutu autori saņem maksājumus. Vari arī padarīt savu maršrutu par maksas maršrutu un saņemt naudu no katras personas, kas to iziet",
    "TRACK POPULARITY AND RECEIVE LIKES": "SEKO POPULARITĀTEI UN SAŅEM LAIKUS",
    "Collect feedback": "Apkopo atsauksmes, uzlabo maršrutu un iegūsti vairāk pabeigto maršrutu. Jo vairāk cilvēku veiks tavu maršrutu, jo vairāk nopelnīsi!",
    "ARTIFACT REWARDS": "KO TU SAŅEM PAR ARTEFAKTIEM",
    "An artifact is a 'treasure chest' you find at the end of your journey, a culmination of the completed track. Depending on the route and complexity of the program, an artifact can yield a variety of rewards:": "Artefakts ir “dārgumu lāde”, ko tu atrod ceļojuma beigās, maršruta kulminācijā. Atkarībā no programmas maršruta un sarežģītības artefaktam var būt dažādas izpausmes:",
    "A gift from a Project Partner": "Dāvana no projekta partnera",

    "Access to premium features of the application: exclusive quests and routes, valuable prizes": "Piekļuve lietotnes premium funkcijām: ekskluzīviem kvestiem, īpašiem maršrutiem un vērtīgām balvām",
    "Prize cost in in-game currency. You can use the currency to exchange artifacts and discover new fascinating routes": "Izmaksas spēles valūtā. Tieši izmantojot to tu vari mainīties ar artefaktiem un atvērt jaunus interesantus maršrutus",

    "PARTICIPATE IN THE SEARCH FOR VALUABLE ARTIFACTS": "PIEDALIES VĒRTĪGU ARTEFAKTU MEKLĒŠANĀ",
    "CREATE AN INTERESTING ROUTE OR QUEST": "IZVEIDO INTERESANTU MARŠRUTU VAI KVESTU",
    "WHAT YOU GET FOR THE ARTIFACTS": "KO TU SAŅEM PAR <strong>ARTEFAKTIEM</strong>",
    "When participants collect artifacts during the quests": "<strong>Artefakts</strong> ir “dārgumu lāde”, ko tu atrod ceļojuma beigās, maršruta kulminācijā. Atkarībā no programmas maršruta un sarežģītības artefaktam var būt dažādas izpausmes:",
    "Gain access to the premium features of the app: exclusive quests, special routes, and valuable prizes": "Piekļuve lietotnes premium funkcijām: ekskluzīviem kvestiem, īpašiem maršrutiem un vērtīgām balvām",
    "Cost in in game currency": "Izmaksas spēles valūtā. Tieši izmantojot to tu vari mainīties ar artefaktiem un atvērt jaunus interesantus maršrutus"
  },
  about: {
    "Choose a route based on your interests": "IZVĒLĒTIES MARŠRUTU ATBILSTOŠI SAVĀM INTERESĒM",
    "Sorting by filters will help you create the journey of your dreams": "Kārtošana pēc filtriem palīdzēs tev izveidot savu sapņu ceļojumu.",
    "Gain access to related travel services": "Iegūsti piekļuvi saistītajiem ceļojumu pakalpojumiem",
    "Participate in an exciting game with augmented reality elements": "Piedalies aizraujošā spēlē ar paplašinātās realitātes elementiem.",
    "Compete with other travelers in treasure hunting": "Sacenties ar citiem ceļotājiem dārgumu meklēšanā",
    "Explore unknown and rediscover familiar places": "Izpēti nezināmās un no jauna atklāj pazīstamās vietas.",
    "Create your own routes": "Izveido savus maršrutus",
    "Feel like a treasure hunter": "Jūties kā dārgumu meklētājs",
    "Artifactum is": "<strong>Artifactum</strong> ir ceļvedis pa neparastām vietām, kur katrs maršruts ir aizraujošs kvests ar paplašinātās realitātes elementiem.<br> Šis ir jauns ceļojuma formāts, kas apvieno izzināšanas un spēļu pieredzi.",
    "You select a program": "Izvēlies programmu, pamatojoties uz savām interesēm, apmeklē pazīstamus muzejus vai staigā pa gleznainām kalnu takām, vienlaikus piedaloties vērtīgu artefaktu meklēšanā",
    "The application": "Lietojumprogramma nodrošina arī piekļuvi ceļojumu pakalpojumiem visā maršrutā: ceļvežu atrašanai, viesnīcu rezervēšanai un galdiņu rezervēšanai restorānos",
    "How does it work?": "Kā tas darbojas?",
    "Along the route, there are checkpoints that are recorded using augmented reality technology": "Maršrutā ir kontrolpunkti, kuru sasniegšana tiek reģistrēta, izmantojot paplašinātās realitātes tehnoloģiju",
    "The final point of": "Maršruta finiša punktā ir dārgumu lāde ar artefaktu. Šo dārgumu var iegūt tikai pēc visu maršruta kontrolpunktu iziešanas",
    "The more valuable the artifact": "Jo vērtīgāks ir artefakts, jo vairāk iespēju tev ir: Tu iegūsti piekļuvi jauniem maršrutiem un iespēju pārdot artefaktu par kriptovalūtu biržā",
    "What other features does the application provide?": "Kādas citas funkcijas nodrošina lietojumprogramma?",
    "It offers the selection of tourist routes based on your current location": "Tā piedāvā tūrisma maršrutu izvēli, balstoties uz tavu pašreizējo atrašanās vietu",
    "Answer to the question": "Atbilde uz jautājumu: 'Ko interesantu es varu šeit ieraudzīt?' ja vēlies iegūt koncentrētus iespaidus ierobežotā laikā",
    "Help in planning your trip": "Palīdzība ceļojuma plānošanā. Iepazīsties ar maršrutiem dažādos reģionos, skaistas fotogrāfijas no svarīgākajām vietām kalpos kā papildu iedvesmas avots",
    "Booking and payment for hotels": "Viesnīcu, kafejnīcu, muzeju rezervēšana un apmaksa. Ceļojumu pakalpojumi vienā lietotnē",
    "The ability to share photos on social networks and with friends in the app": "Iespēja koplietot fotoattēlus sociālajos tīklos un ar draugiem lietotnē",
    "Team treasure hunt competitions": "Komandu dārgumu meklēšanas sacensības. Izveido lietotāju grupas un sāc tajās uz maršrutu balstītas sacensības",
    "Every month, access new tracks": "Katru mēnesi piekļūsti jauniem maršrutiem",
    "Personal guide services along the route": "Personīgā gida pakalpojumi maršrutā",
    "Save on food, accommodation, and entertainment thanks to special conditions and promotions from our partners": "Ietaupi uz pārtiku, izmitināšanu un izklaidi, pateicoties īpašiem nosacījumiem un mūsu partneru akcijām",

    "travel with enjoyment": "ПУТЕШЕСТВУЙТЕ C <strong>УДОВОЛЬСТВИЕМ!</strong>"
  },
  partners: {
    "FOR PARTNERS AND AUTHORS": "PARTNERIEM UN AUTORIEM",
    "Who benefits from collaborating with us": "Kam ir izdevīgi ar mums sadarboties?",
    "For tour companies": "Tūrisma uzņēmumiem",
    "Are you an": "Jūs esat pilsētas pastaigu vai ekstrēmu ekskursiju organizators? Neatkarīgi no Jūsu programmu formāta Jūs iegūsiet piekļuvi savai mērķauditorijai un piedāvāsiet tai pilnīgi jauna formāta ceļojumus. Publicējiet savus piedāvājumus mūsu platformā un izveidojiet unikālas ekskursijas, kas atšķiras no Jūsu konkurentu programmām. Ceļojumā -kvestā tūristi jūtas kā varoņi filmās par Indianu Džounsu vai Laru Kroftu, un Jūs iegūstiet emocionālu saikni – viņi Jūs atcerēsies kā ceļojumu organizatoru, kas māk atstāt spilgtus iespaidus. Mēs piedāvājam Jums platformu Jūsu ekskursiju un programmu prezentēšanai, rezervēšanai un apmaksai. Vai vēlaties kļūt par partneri, iegūt jaunus klientus un palielināt esošo lojalitāti? Detalizētas instrukcijas un mācību materiālus par maršruta izveidi un sistēmas darbību nosūtīsim uz Jūsu e-pastu",
    "For guides and tour operators": "Gidiem un tūroperatoriem",
    "Publish routes": "Publicējiet maršrutus no savas kolekcijas ar audio komentāriem un fotoattēliem. Bez Jūsu klātbūtnes simtiem tūristu izies Jūsu programmas. Ja maršruts kļūst populārs, Jūs nopelnāt naudu par katru tūrista unikālo maršruta iziešanu, kā arī balvas no mūsu partneriem. Turklāt Jūs varat noteikt cenu par maršruta iziešanu un saņemt naudu katru reizi, kad tūrists iziet Jūsu maršrutu. Vēlies pievienoties komandai? Atstāj savu kontaktinformāciju zemāk esošajā formā",
    "For cafes, bars, shops, restaurants, theaters, museums": "Kafejnīcām, bāriem, veikaliem, restorāniem, teātriem, muzejiem",
    "Place artifacts near": "Izvietojiet artefaktus savu uzņēmumu tuvumā vai iekšienē un piesaistiet jaunus klientus! Artefaktu var novietot vienā no maršrutiem, kas iet gar Jūsu uzņēmumu. Ja šādu maršruta vēl nav, Jums ir iespēja to izveidot bez maksas. Jo populārāks un interesantāks būs kvests, jo vairāk apmeklētāju Jūs potenciāli varēsiet piesaistīsiet. Lietotnē varat arī ievietot informāciju par atlaidēm, īpašajiem piedāvājumiem un jaunumiem ēdienkartē. Lietotāji šo informāciju redzēs uznirstoša paplašinātās realitātes elementa veidā – šādam kreatīvam reklāmas gājienam ir daudz lielākas izredzes gūt panākumus!",
    "Personal account": "Lietotājkonts",
  },
  feedback: {
    "Enter name": "Ievadiet vārdu",
    "Name": "Vārds",
    "Enter e-mail": "Ievadiet e-pastu",
    "Enter your message": "Ievadiet savu ziņojumu",
    "Send": "Nosūtīt",
    "Input password": "Input password",

  },

  "footer": {
    "Privacy Policy": "Privātuma politika"
  },
  "contacts": "Kontakti",
  "routes": "maršruti",
  "quests": "uzdevumi",
  "authors": "autori",
  "gifts": "dāvanas",
  
  "Log In": "Pieteikties",
  "Advertising partner": "Reklāmas partneris",
  "Author": "Autors",
  "To come in": "Ienākt",
  "Forgot your password": "Aizmirsi paroli",

  "cont": {
    "under development": "Šī lapa ir izstrādes stadijā..."
  }

}
