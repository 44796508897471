<!-- @format -->

<template>
	<div class="socials-share">
		<div class="group-button">
			<googleStore-button />
			<appStore-button />
		</div>

		<div class="qr-code">
			<img width="200" height="200" src="@assets/img/app/qr-android.png" />
			<img width="200" height="200" src="@assets/img/app/qr-ios.png" />
		</div>
	</div>
</template>

<script lang="ts" setup>
import Vue, { onMounted } from "vue";
import { GoogleStoreButton, AppStoreButton } from "@/components";

onMounted(() => {
	const os = getMobileOperatingSystem();
	console.log("Operating System:", os);
});
const getMobileOperatingSystem = (): string => {
	const userAgent = navigator.userAgent || navigator.vendor || (window as any)?.opera;

	// Проверяем устройства на базе Android
	if (/android/i.test(userAgent)) {
		return "Android";
	}
	if (/Windows/.test(userAgent)) {
		return "Windows";
	}

	// Проверяем устройства на базе iOS
	if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any)?.MSStream) {
		return "iOS";
	}

	return "unknown";
};
</script>

<style lang="css" scoped>
.qr-code {
	gap: 5px;
}
.socials-share {
	display: flex;
	column-gap: 24px;
	margin-top: 58px;
}

.group-button {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	/* align-items: center; */
}

@media all and (max-width: 1300px) {
	.group-button {
		flex-direction: column;
		align-items: flex-start;
	}

	.btn--animate:first-child {
		margin-bottom: 10px;
	}
}

@media all and (max-width: 1000px) {
	.group-button {
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.btn--animate:first-child {
		margin-bottom: 0;
	}

	.socials-share {
		justify-content: center;
	}

	.group-button {
		width: 100%;
	}
}

@media all and (max-width: 570px) {
	.group-button {
		flex-direction: column;
		align-items: center;
	}

	.btn--animate:first-child {
		margin-bottom: 10px;
	}
}

.qr-code {
	display: none;
	justify-content: center;
	/* padding-top: 40px; */
}

.qr-code > img {
	width: 142px;
	height: 142px;
}

@media (min-width: 992px) {
	.qr-code {
		display: flex;
	}
}

@media (min-width: 1000px) and (max-width: 1300px) {
	.qr-code {
		max-width: 271px;
	}
}

@media (min-width: 1301px) {
	.qr-code {
		justify-content: start;
	}
}
</style>
