

export default class PageRequest  {

	Skip?: number;
	Take?: number;
	constructor(obj?: Partial<PageRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
