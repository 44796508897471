
import SchedulePointModel from './SchedulePointModel';
import { CropImageModel } from './CropImageModel';
import MarkerMapModel from '../models/MarkerMapModel';

export default class MapPointMarkerModel extends MarkerMapModel {
  constructor(obj?: MapPointMarkerModel) {
    super(
      new MarkerMapModel({
        lat: (obj.Latitude ?? 0).toString(),
        lng: (obj.Longitude ?? 0).toString(),
      })
    );
    Object.assign(this, obj);
  }


  Id: number = 0;
  Title?: string;
  Description?: string;
  Number: number = 0;
  Latitude: number = 0;
  Longitude: number = 0;
  DetailUrl: string;
  PreviewImages?: Array<CropImageModel>;
  ImageId?: number;
  Count?: number;
  SchedulePoint?: SchedulePointModel
}
