

export default class MapPointCoordinates  {
	Latitude: number;
	Longitude: number;
	constructor(obj?: Partial<MapPointCoordinates>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
