
import EnumMapRouteTypeTransmission from './EnumMapRouteTypeTransmission';
import MapCoordinates from './MapCoordinates';
import PageRequestModels from './PageRequestModels';
import EnumBizMapRouteStatus from "./EnumBizMapRouteStatus"
import EnumBizMapRouteType from './EnumBizMapRouteType';

export default class GetMapRouteClustersRequest  {
	LeftTopPoint: MapCoordinates;
	RightBottomPoint: MapCoordinates;
	Type?: EnumMapRouteTypeTransmission;
	ZoomLevel: number;
	Search?: string;
	Page: PageRequestModels;
	OnlyFree?: boolean = false;
	ShowPassed: boolean = false;
	RouteType?: EnumBizMapRouteType;
	Status: Array<EnumBizMapRouteStatus>;
	TimeFrom?: number;
	TimeTo?: number;
	DistanceFrom?: number;
	DistanceTo?: number;
	AuthorId?: number;
	SearchAuthor?: string;
	DateStart?: Date;
	DateEnd?: Date;
	constructor(obj?: Partial<GetMapRouteClustersRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
