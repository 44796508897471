export default {
  menubar: {
    "About": "ABOUT THE APP",
    "To partners and authors": "FOR PARTNERS AND AUTHORS",
    "Feedback": 'FEEDBACK'
  },
  homepage: {
    "TURN YOUR JOURNEY INTO AN EXCITING GAME!":"TURN YOUR JOURNEY INTO AN<br><strong> EXCITING GAME!</strong>",
    "Get on Google Play":"Get on Google Play",
    "Get on App Store":"Get on App Store",
    "UNIQUE ROUTES AND QUESTS ENHANCED WITH AUGMENTED REALITY FEATURES":"UNIQUE ROUTES AND QUESTS ENHANCED WITH <br>AUGMENTED  <strong> REALITY FEATURES </strong>",
    "SELECT A ROUTE YOU FIND APPEALING":"SELECT A ROUTE YOU FIND APPEALING",
    "A flexible filter system will help you assemble the journey of your dreams":"A flexible filter system will help you assemble the journey of your dreams",
    "VISIT SCENIC LOCATIONS":"VISIT SCENIC LOCATIONS",
    "The most interesting locations suggested by locals and enthusiasts of the respective regions":"The most interesting locations suggested by locals and enthusiasts of the respective regions",
    "UNDERTAKE EXCITING QUESTS":"UNDERTAKE EXCITING QUESTS",
    "Experience the thrill of being an adventure movie character in pursuit of hidden treasure":"Experience the thrill of being an adventure movie character in pursuit of hidden treasure",
    "SEARCH FOR VALUABLE ARTIFACTS":"SEARCH FOR VALUABLE ARTIFACTS",
    "A fundamentally new travel experience, enhancing the amazement of discovery through augmented reality technologies":"A fundamentally new travel experience, enhancing the amazement of discovery through augmented reality technologies",
    "ENJOY GIFTS FROM OUR PARTNERS":"ENJOY GIFTS FROM OUR PARTNERS",
    "Discounts at cafés, restaurants and hotels":"Discounts at cafés, restaurants and hotels",
    "CREATE AND SHARE YOUR OWN ROUTES":"CREATE AND SHARE <strong>YOUR OWN</strong> ROUTES",
    "Ever wanted to make money on traveling? Now, the opportunity is yours!": "Ever wanted to make money on traveling? <br> Now, the opportunity is yours!",
    "DEVELOP AN INTERESTING ROUTE OR QUEST":"DEVELOP AN INTERESTING ROUTE OR QUEST",
    "Share your favorite locations and inspire others to travel!":"Share your favorite locations and inspire others to travel!",
    "RECEIVE PARTNER REMUNERATION": "RECEIVE PARTNER REMUNERATION",
    "30% of the cost of advertisements placed along your route will be credited to your account":"30% of the cost of advertisements placed along your route will be credited to your account",
    "EARN WITH PROGRAMS": "EARN WITH PROGRAMS",
    "Authors of the most popular routes receive payouts":"Authors of the most popular routes receive payouts. You can also make a paid route and earn each time anyone decides to take it",
    "TRACK POPULARITY AND RECEIVE LIKES":"TRACK POPULARITY AND RECEIVE LIKES",
    "Collect feedback":"Collect feedback, improve your route and attract more adventurers. The more people choose to complete your route, the more you earn!",
    "ARTIFACT REWARDS":"ARTIFACT REWARDS",
    "An artifact is a 'treasure chest' you find at the end of your journey, a culmination of the completed track. Depending on the route and complexity of the program, an artifact can yield a variety of rewards:": "An artifact is a 'treasure chest' you find at the end of your journey, a culmination of the completed track. Depending on the route and complexity of the program, an artifact can yield a variety of rewards:",
    "A gift from a Project Partner":"A gift from a Project Partner",
    "Access to premium features of the application: exclusive quests and routes, valuable prizes":"Access to premium features of the application: exclusive quests and routes, valuable prizes",
    "Prize cost in in-game currency. You can use the currency to exchange artifacts and discover new fascinating routes": "Prize cost in in-game currency. You can use the currency to exchange artifacts and discover new fascinating routes",

    "PARTICIPATE IN THE SEARCH FOR VALUABLE ARTIFACTS": "PARTICIPATE IN THE SEARCH FOR VALUABLE ARTIFACTS",
    "CREATE AN INTERESTING ROUTE OR QUEST": "CREATE AN INTERESTING ROUTE OR QUEST",
    "WHAT YOU GET FOR THE ARTIFACTS": "WHAT YOU GET FOR <strong>THE ARTIFACTS</strong>",
    "When participants collect artifacts during the quests": "When participants collect artifacts during the quests, there might be additional rewards or recognition tied to these achievements.Check the specific details provided in your quest creation platform to understand what perks or rewards are associated with collecting artifacts.This could range from virtual points, badges, and rankings to physical rewards or certificates, depending on how the platform and its partners have structured the incentives: ",
    "Gain access to the premium features of the app: exclusive quests, special routes, and valuable prizes": "Gain access to the premium features of the app: exclusive quests, special routes, and valuable prizes",
    "Cost in in game currency": "Prize cost in in-game currency. You can use the currency to exchange artifacts and discover new fascinating routes"
  },
  about: {
    "Choose a route based on your interests": "Choose a route based on your interests",
    "Sorting by filters will help you create the journey of your dreams": "Sorting by filters will help you create the journey of your dreams",
    "Gain access to related travel services":"Gain access to related travel services",
    "Participate in an exciting game with augmented reality elements": "Participate in an exciting game with augmented reality elements",
    "Compete with other travelers in treasure hunting":"Compete with other travelers in treasure hunting",
    "Explore unknown and rediscover familiar places":"Explore unknown and rediscover familiar places",
    "Create your own routes":"Create your own routes",
    "Feel like a treasure hunter":"Feel like a treasure hunter",
    "Artifactum is":"<strong>Artifactum</strong> is a guide to unusual places, where each route is an exciting quest with augmented reality elements. This is a new format of travel that combines educational and gaming experiences",
    "You select a program":"You select a program based on your interests, visit iconic museums or walk through scenic mountain trails, while simultaneously participating in the search for valuable artifacts",
    "The application":"The application also provides access to travel services along the route: finding guides, booking hotels, and reserving tables at restaurants",
    "How does it work?":"How does it work?",
    "Along the route, there are checkpoints that are recorded using augmented reality technology":"Along the route, there are checkpoints that are recorded using augmented reality technology",
    "The final point of": "The final point of the route contains a treasure chest with an artifact.This treasure can only be obtained after passing all the checkpoints on the route",
    "The more valuable the artifact":"The more valuable the artifact, the more opportunities you have: you gain access to new routes and the ability to sell the artifact for cryptocurrency on an exchange",
    "What other features does the application provide?":"What other features does the application provide?",
    "It offers the selection of tourist routes based on your current location":"It offers the selection of tourist routes based on your current location",
    "Answer to the question": "Answer to the question: 'What interesting things can I see here?' when you want to get concentrated impressions in a limited time",
    "Help in planning your trip":"Help in planning your trip. Get acquainted with trails in various regions, and vivid photos of key locations will serve as an additional source of inspiration",
    "Booking and payment for hotels":"Booking and payment for hotels, cafes, museums. Travel services in one app",
    "The ability to share photos on social networks and with friends in the app":"The ability to share photos on social networks and with friends in the app",
    "Team treasure hunt competitions":"Team treasure hunt competitions. Create custom groups and launch route-based competitions within them",
    "Every month, access new tracks": "Every month, access new tracks",
    "Personal guide services along the route": "Personal guide services along the route",
    "Save on food, accommodation, and entertainment thanks to special conditions and promotions from our partners": "Save on food, accommodation, and entertainment thanks to special conditions and promotions from our partners",
    "travel with enjoyment": "Travel with enjoyment"
  },
  partners: {
    "FOR PARTNERS AND AUTHORS":"FOR PARTNERS AND AUTHORS",
    "Who benefits from collaborating with us": "Who benefits from collaborating with us ? ",
    "For tour companies":"For tour companies",
    "Are you an":"Are you an organizer of city walks or extreme tours? Regardless of the format of your programs, you gain access to your target audience and offer them journeys of a fundamentally new format. Publish your offers on our platform and create unique tours that stand out from your competitors' programs. In the adventure-quest journey, tourists feel like heroes of movies about Indiana Jones or Lara Croft, and you establish an emotional connection - they will remember you as an organizer capable of leaving a lasting impression. We offer you a platform for presentation, booking, and payment of your tours and programs. Want to become a partner, acquire new clients, and increase loyalty among existing ones? We will send you detailed instructions and training materials on route creation and system operation to your email",
    "For guides and tour operators":"For guides and tour operators",
    "Publish routes": "Publish routes from your collection with audio commentary and photos.Without your presence, hundreds of tourists will be completing your programs.If a track becomes popular, you earn money for every unique completion of your track by a tourist, as well as prizes from our partners.In addition, you can set a price for passing the route and receive money each time a tourist completes your route.Want to join the team? Leave your contact details in the form below",
    "For cafes, bars, shops, restaurants, theaters, museums":"For cafes, bars, shops, restaurants, theaters, museums",
    "Place artifacts near":"Place artifacts near or inside your establishments to attract new customers! An artifact can be placed on one of the routes near your venue. If there are no such routes yet, you have the opportunity to create one for free. The more popular and interesting the quest, the more visitors you can potentially attract. You can also post information about discounts, special offers, and new menu items in the app. Users will see this information in the form of a pop-up augmented reality element – such a creative advertising move has a much better chance of success!",
    "Personal account": "Personal account",
  },
  feedback: {
    "Enter name": "Enter name",
    "Name": "Name",
    "Enter e-mail":"Enter e-mail",
    "Enter your message": "Enter your message",
    "Send": "Send",
    "Input password": "Input password",

  },
  footer: {
    "Privacy Policy": "Privacy Policy"
  },
  "contacts": "contacts",
  "routes": "routes",
  "quests": "quests",
  "authors": "authors",
  "gifts": "gifts",

  "Log In": "Log In",
  "Advertising partner": "Advertising partner",
  "Author": "Author",
  "To come in": "To come in",
  "Forgot your password": "Forgot your password",
  cont: {
    "under development": "Under development..."
  }
}
